
import { Loading, Message } from "element-ui"; //项目已经全局引入element的话可以不单独引入

import { sleep, startLoading, endLoading, dateFormat,encryptCode } from "@/utils/function";
import API from '@/api/teacher/center/openList.js';
import Tips from "../../../../components/tips.vue";
import { nprogressStart, nprogressClose } from "@/plugins/nprogress.js";
import { request } from "@/request/http";
export default {
    name: "Calendar",
    components: {
        Tips
    },
    data() {
        return {
            resDate: [
                {
                    date: "2021-01-15",
                    content: [
                        {
                            teacherName: "宿艳",
                            time: "8:30-10:30",
                            name: "测试课程等的巴萨所大所大所",
                            id: 2,
                            checkModel: false,
                        },
                        {
                            teacherName: "宿艳",
                            time: "8:30-10:30",
                            name: "有机装置搭建训练-分馏",
                            id: 9,
                            checkModel: false,
                        },
                    ],
                },
            ],
            value: new Date(),
            showDivOpen: false,
            is_open: true,
            projectid: [],
            togetherid: '',
            currentMonth: "",
            checkAll: false,
            togetherList: [],
            projectList: [],
            // 选中日历某一天的全布日程的日期
            selectOneDay: '',
            // 是否展示日程和日期前的复选框
            showcheckBox: false,
            // 统计选择时间
            statisticsTime: '',
            // 展示统计弹窗
            showStatistics: false,
            // 是否显示日期
            showDateSelect:false,
            center_id:"",
            centerList:[],
        };
    },
    created() 
    {
        const self = this;
        var date = new Date();
        self.currentMonth =
            date.getFullYear() +
            "-" +
            (date.getMonth() + 1) +
            "-" +
            date.getDate();
        // 查日历显示数据
        self.getData(self.currentMonth);
    },
    mounted() 
    {
        const self = this;
        /**
         * @name: 点击日历日期，展示月份选择框
         */
        this.$nextTick(() => {
            let dateBtn = document.querySelector(
                ".el-calendar__title"
            );
            dateBtn.addEventListener("click", () => {
                let self = this
                self.showDateSelect = true
            });
        });
        /**
         * @name: 点击前一个月
         */
        this.$nextTick(() => {
            let prevBtn = document.querySelector(
                ".el-calendar__button-group .el-button-group>button:nth-child(1)"
            );
            prevBtn.addEventListener("click", () => {
                var date = new Date(this.value);
                // console.log(this.value);
                self.currentMonth = date
                    .toLocaleDateString()
                    .replace(/\//g, "-");
                self.getData(self.currentMonth);
                // 全选取消
                self.checkAll = false;
            });
        });
        /**
         * @name: 点击今天
         */
        this.$nextTick(() => {
            let prevBtn = document.querySelector(
                ".el-calendar__button-group .el-button-group>button:nth-child(2)"
            );
            prevBtn.addEventListener("click", () => {
                let myDate = new Date();
                let time =
                    myDate.getFullYear() +
                    "-" +
                    Number(myDate.getMonth() + 1) +
                    "-" +
                    myDate.getDate();
                // console.log(time);
                self.getData(time);
                // 全选取消
                self.checkAll = false;
            });
        });
        /**
         * @name: 点击后一个月
         */
        this.$nextTick(() => {
            let prevBtn = document.querySelector(
                ".el-calendar__button-group .el-button-group>button:last-child"
            );
            prevBtn.addEventListener("click", () => {
                var date = new Date(this.value);
                self.currentMonth = date
                    .toLocaleDateString()
                    .replace(/\//g, "-");
                self.getData(self.currentMonth);
                // 全选取消
                self.checkAll = false;
            });
        });
    },
    methods: {
        changeValue(e) 
        {
            let self = this;
            if (!e) 
            {
                self.showDateSelect = false
                return
            }
            let date = new Date(self.value);
            self.currentMonth = date
                .toLocaleDateString()
                .replace(/\//g, "-");
            self.getData(self.currentMonth);
            // 全选取消
            self.checkAll = false;
        },

        openStudentsList(open_id, open_name, date, time, class_uname) 
        {
            window.sessionStorage.setItem('havename', 2);
            // this.$router.push(`/manager/teacher/studentslist?id=${open_id}`)
            window.open("#/teacher/center/studentsList?id=" + open_id + '&hideMenu=' + 1 + '&name=' + open_name + "/" + " " + date + " " + time + " " + class_uname)
        },

        courseChange() 
        {
            try 
            {
                const self = this;
                let data = {
                    openCourseId: self.openCourseId
                };
                API.getProjectListByCourseid(data)
                    .then(result => {
                        if (result.code > 0) 
                        {
                            self.openProjectList = result.list;
                        }
                    })
            }
            catch (error) 
            {
                Message.error('系统错误')
            }
        },

        dealMyDate(param) {
            const self = this;
            let len = this.resDate.length;
            let res = "";
            for (let i = 0; i < len; i++) 
            {
                if (this.resDate[i].datetime == param.day) 
                {
                    this.resDate[i].content.forEach((item) => {
                        if (item.date == self.selectOneDay.day) 
                        {
                            item.checkModel = self.selectOneDay.checkOneDay
                        }
                    })
                    res = this.resDate[i].content;
                    break;
                }
            }
            return res;
        },

        searchContent() 
        {
            const self = this;
            // 查询列表
            self.getData(self.currentMonth);
        },

        prevMonth(str) 
        {
            var arr = str.split("-"); //将返回[]
            let year = arr[0];
            let month = arr[1];
            if (month == 1) 
            {
                year = year - 1;
                month = 12;
            } 
            else 
            {
                month = month - 1;
            }
            let time;
            if (month != 10 && month != 11 && month != 12) 
            {
                time = year + "-0" + month + "-" + 25;
            } 
            else 
            {
                time = year + "-" + month + "-" + 25;
            }
            return time;
        },

        nextMonth(str) 
        {
            var arr = str.split("-"); //将返回[]
            let year = arr[0];
            let month = arr[1];
            if (month == 12) 
            {
                year = Number(year) + 1;
                month = 1;
            } 
            else 
            {
                month = Number(month) + 1;
            }
            let time;
            if (month != 10 && month != 11 && month != 12) 
            {
                time = year + "-0" + month + "-" + 15;
            } 
            else 
            {
                time = year + "-" + month + "-" + 15;
            }
            return time;
        },

        getData(str) 
        {
            nprogressStart();
            let self = this;
            try 
            {
                let prevTime = self.prevMonth(str);
                let nextTime = self.nextMonth(str);
                let data = {
                    prevTime: prevTime,
                    nextTime: nextTime,
                    togetherid: self.togetherid,
                    projectid: self.projectid,
                    center_id: self.center_id
                };
                // 开启loading
                nprogressStart();
                self.$Loading.show();
                // 发起获取日历显示数据请求
                self.$Loading.show();
                    request("POST", `/index.php/adminCenter/getSchoolAdminOpenList`, data)
                        .then((result) => {
                            nprogressClose();
                            self.$Loading.hide();
                            if(result.code > 0 )
                            {
                                self.resDate = result.data;
                                self.projectList = result.projectList;
                                self.togetherList = result.userList;
                                self.centerList = result.centerList;
                            }
                        })     
                /*API.getOpenListByDate(data)
                    .then((result) => {
                        if (result.code > 0) 
                        {
                            self.resDate = result.data;
                            self.projectList = result.projectList;
                            self.togetherList = result.userList;
                        }
                        nprogressClose();
                        // 关闭loading
                        self.$Loading.hide();
                    })//*/
            } 
            catch (error) 
            {
                nprogressClose();
                // 关闭loading
                self.$Loading.hide();
            }
        },
    }
};
